if(typeof Marionette !== 'undefined' && typeof rCookieName !== 'undefined') {
  var mySubmitController = Marionette.Object.extend( {
    
    initialize: function() {
      this.listenTo( Backbone.Radio.channel( 'forms' ), 'submit:response', this.actionSubmit );
    },

    actionSubmit: function( response ) {

      if(document.cookie.indexOf(rCookieName) == -1) {  //registration cookie doesn't exist
        rCookieValue = session_id+',';
      } else { //registration cookie does exist, add registration to cookie
        var rCookiePart = document.cookie.substr(document.cookie.indexOf(rCookieName));
        var rEndOfCookie = (rCookiePart.indexOf(';') == -1 ? false : rCookiePart.indexOf(';'));
        rCookieValue = rEndOfCookie ? rCookiePart.substring(rCookiePart.indexOf('=')+1,rEndOfCookie) : rCookiePart.substring(rCookiePart.indexOf('=')+1);
        //rCookieValue should be a string of comma seperated session_ids
        //find if this session_id is in the rCookieValue
        if(rCookieValue.indexOf(session_id+',') != -1) {
          //if it's in there this stuff shouldn't even be loaded
          console.log('user is already registered for session, something is broken');
        } else { //if the company isn't in the rCookieValue let's add it to the end
          rCookieValue += session_id+',';
        }
      }

      //set registration cookie
      document.cookie = rCookieName+'='+rCookieValue+'; expires='+rOneYearFromNow+';path=/';

      
      //reload page to display preview content
      location.reload();

    },

  });

  // On Document Ready...
  jQuery( document ).ready( function( $ ) {

      // Instantiate our custom field's controller, defined above.
      new mySubmitController();
  });
}