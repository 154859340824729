// Your main script

jQuery(function ($) {
    $('.events-list__event__add').each(function () {
        let el = $(this);
        var myCalendar = createCalendar({
            options: {
                class: 'my-calendar-generated-class',
            },
            data: {
                title: el.data("title"),
                start: new Date(el.data("start")),
                end: new Date(el.data("end")),
                address: el.data("address"),
                description: el.data("description")
            }
        });
        el.append(myCalendar);
    });

    const sing_in_page = jQuery('.page-template-sign-up-template');

    if (sing_in_page.length) {
        setTimeout(function () {
            window.location = 'https://amplify-community.nabshow.com/signup';
        }, 2000)
    }

    jQuery('.js-mobile-toggle').on('click', function () {
        jQuery(this).closest('.header').toggleClass('_open');
    });
    jQuery('.site-content').on('click', function () {
        //if mobile nav open, close it
        if (jQuery('.header').hasClass('_open')) {
            jQuery('.header').removeClass('_open');
        }
    });
    jQuery('.js-search-toggle').on('click', function () {
        jQuery(this).closest('.menu__search').toggleClass('_open');
        if (jQuery('.menu__search').hasClass('_open')) {
            jQuery('.search__field').focus();
        }
    })
    jQuery('.js-toggle-feedchooser').on('click', function () {
        jQuery(this).parent().toggleClass('_open');
    });
    var closeFeedchooser;
    jQuery('.js-toggle-feedchooser').parent().on('mouseout', function () {
        var self = jQuery(this);
        closeFeedchooser = setTimeout(function () {
            jQuery(self).removeClass('_open');
        }, 500);
    }).on('mouseover', function () {
        clearTimeout(closeFeedchooser);
    });

    jQuery('.radiolist li .option').on('click', function () {
        var self = jQuery(this);
        if (jQuery(this).hasClass('_selected')) {
            jQuery('.js-toggle-feedchooser').parent().removeClass('_open');
        } else {
            var panel = jQuery(self).attr('data-panel');
            var title = jQuery(self).text();
            jQuery(self).parents('.showlist').children('.js-toggle-feedchooser').html(title);
            panel = '.js-panel-' + panel;
            var panelSlider = panel + ' .slides__list';
            jQuery('.radiolist li .option').removeClass('_selected');
            jQuery(self).addClass('_selected');
            closeFeedchooser = setTimeout(function () {
                jQuery('.js-toggle-feedchooser').parent().removeClass('_open');
            }, 250);
            jQuery('.discover__panel').removeClass('_selected');
            jQuery(panel).addClass('_selected');
            jQuery(panelSlider).slick('setPosition');
        }

    });



    jQuery('.slides__list').not('.slick-initialized').slick({
        fade: true,
        cssEase: 'linear',
        adaptiveHeight: true
    });


    jQuery('.featured__carousel--wrapper').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 978,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    jQuery('.article_related_carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    jQuery('.featured__author--carousel').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 978,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });


    setTimeout(function () {
        jQuery('.discover__ad, .homeproducts__ad').each(function () {
            var self = jQuery(this);
            var adContainer = self.find('broadstreet-zone');
            var adContent = '';
            if (adContainer.length) {
                adContent = adContainer.children('div').html().trim();
            }
            if (adContent == '') {
                jQuery(self).addClass('_hidden');
            }
        });
    }, 10000);

    jQuery('.homeproducts__image').wrap('<div class="homeproducts__imageWrap"></div>');

    //set the url for this woocommerce error link because it's impossible to find where this is in the code/DB
    jQuery('.woocommerce-error a.showlogin').attr('href', jQuery('.signup__titles .js-signupLink').attr('href'));



    var wooGDPRmsg = jQuery('body.woocommerce-checkout .woocommerce + p').html();
    jQuery('body.woocommerce-checkout .woocommerce-checkout-review-order').append("<div class='wooGDPRmsg'>" + wooGDPRmsg + "</div>");
    jQuery('body.woocommerce-checkout .woocommerce + p').remove();


    //remove in-content article side bar
    var article_content = jQuery('.js-article_content');
    if (article_content) {
        //console.log('.js-article_content exists');
        var article_cols = jQuery('.js-article_content .article-content > .wp-block-column');
        //console.log('article_cols length: ' + article_cols.length);
        jQuery(article_cols[0]).css('flex-basis', '100%');
        jQuery(article_cols[1]).remove();
        //console.log('second col removed');
        var article_ad1 = jQuery('.js-article_ad1');
        var article_ad2 = jQuery('.js-article_ad2');
        var article_tallad = jQuery('.js-article_tallad');
        var article_signup = jQuery('.js-article_signup');
        var article_related = jQuery('.js-article_related');
        //article sections
        var article_children = jQuery(article_cols[0]).children();

        var article_4th = Math.floor(article_children.length / 4);
        var ad_spot_m1 = jQuery(article_children[article_4th]);
        var ad_spot_m2 = jQuery(article_children[article_4th + article_4th]);
        var ad_spot_m3 = jQuery(article_children[article_4th + article_4th + article_4th]);

        var article_3rd = Math.floor(article_children.length / 3);
        var ad_spot_d1 = jQuery(article_children[article_3rd]);
        var ad_spot_d2 = jQuery(article_children[article_3rd + article_3rd]);

        //console.log(jQuery(window).width());
        if (jQuery(window).width() < 1024) {
            //single column mobile and tablet behavior
            jQuery(ad_spot_m1).after(jQuery(article_tallad).html());
            jQuery(article_tallad).remove();
            jQuery(ad_spot_m2).after(jQuery(article_ad1).html());
            jQuery(article_ad1).remove();
            jQuery(ad_spot_m3).after(jQuery(article_ad2).html());
            jQuery(article_ad2).remove();
            //console.log('narrow screen ads moved');
        } else {
            //2 column desktop behavior
            jQuery(ad_spot_d1).after(jQuery(article_ad1).html());
            jQuery(article_ad1).remove();
            jQuery(ad_spot_d2).after(jQuery(article_ad2).html());
            jQuery(article_ad2).remove();
            //console.log('wide screen ads moved');
        }

        if (!article_cols) {
            console.log('article columns not found');
        }
    } else {
        //console.log('.js-article_content not found');
    }


    //session modal
    jQuery('.js-sessionwatch').on('click', function (e) {
        e.preventDefault();
        jQuery('#sessionvideo').toggleClass('_shown');
        window.setTimeout(function () {
            jQuery('#sessionvideo').removeClass('_corner');
        }, 300);

    });
    //Stop Brightcove player if the video modal is closed
    jQuery('.js-sessionwatch.modal__close').on('click', function (e) {
        $(".embed-wrapper._video iframe").attr("src", $(".embed-wrapper._video iframe").attr("src"));
    });
    jQuery('.js-sessionresize').on('click', function (e) {
        e.preventDefault();
        if (jQuery('#sessionvideo').hasClass('_corner')) {
            jQuery('#sessionvideo').removeClass('_corner');
            jQuery(this).text('Shrink Modal Video').attr('title', 'Shrink Modal Video');
        } else {
            jQuery('#sessionvideo').addClass('_corner');
            jQuery(this).text('Expand Modal Video').attr('title', 'Expand Modal Video');
        }

    });
});
