
jQuery(function ($) {
    const ElasticAppSearch = window.ElasticAppSearch;

    var client = ElasticAppSearch.createClient({
        searchKey: "search-19ok55szwvx3qqckj4vqwnue",
        endpointBase: "https://nab-elastic-search-1.ent.us-central1.gcp.cloud.es.io",
        engineName: "amplify-wp"
    });
    
    $('body').on('click', function (e) {
        if (e.target !== $('.elastic-search-results')[0] && e.target !== $('.elastic-search-input')[0]) {
            $('.elastic-search-results').fadeOut(200);
        }
    })

    $('.close-elastic-search-modal').on('click', function () {
        $(this).closest('.elastic-search-modal').fadeOut(200);
        $('body').removeClass('is-open-mobile-search');
    });

    let SearchOptions = {
        "page": {
            "current": 1,
            "size": 10
        }
    }

    $('.elastic-search-input').on("input click", function (e) {

        let value = $(this).val();

        if ($(window).width() >= 1024 || 'click' === e.type && $(window).width() >= 1024) {
            if (value.length) {
                elastic_search(
                    value,
                    SearchOptions,
                    '.elastic-search-results',
                    '.elastic-search-results__container',
                    false
                );
            }
        }

        if ($(window).width() < 1024 && 'input' === e.type) {
            $('.more-results-mobile').addClass('active');
        }
    });

    $('.elastic-search-input').on("keypress", function (e) {

        let value = $(this).val();

        if (e.which === 13 && value.length) {

            elastic_search(
                value,
                SearchOptions,
                '.elastic-search-modal',
                '.search-results',
                true
            );

            $('body').addClass('is-open-mobile-search');
        }
    })

    $('.elastic-search-input-modal').on("input", function (e) {

        let value = $(this).val();

        elastic_search(
            value,
            SearchOptions,
            '.elastic-search-modal',
            '.search-results',
            true
        );
    });

    $(document).on('click', '.search-pagination-btn', function () {

        let page = $(this).attr('data-page');
        let value = $(this).closest('.elastic-search-modal').find('.elastic-search-input-modal').val();

        elastic_search(
            value,
            {
                "page": {
                    "current": parseInt(page),
                    "size": 10
                }
            },
            '.elastic-search-modal',
            '.search-results',
            true
        );
    })

    function elastic_search(value, data, parent, child, modal) {

        client.search(value, data)
            .then(resultList => {

                let links = '';
                console.log(resultList)
                resultList.results.forEach(result => {

                    let content = '';

                    if (modal) {
                        content = '';

                        if (result.getRaw('meta_description')) {
                            content = `<span>${result.getRaw('meta_description')}</span>`;
                        }
                    }

                    let link = `<div class="search-result"><a id="${result.getRaw("id")}" href="${result.getRaw("url")}">${result.getRaw("title")}</a>${content}</div>`;

                    links += link;
                });

                let paginationData = resultList.info.meta.page;

                if (!modal && paginationData.total_pages > 1) {
                    links += '<div class="more-results">To See More Search Results, Hit Enter...</div>';
                }

                if(!resultList.results.length){
                    links += '<div class="more-results no-results">No results was found...</div>';
                }

                if (modal) {

                    $(parent).find('.elastic-search-input-modal').val(value);

                    if (!$('.elastic-search-results').hasClass('.hidden')) {
                        $('.elastic-search-results').addClass('hidden').fadeOut(0);
                    }

                    let summary = $(parent).find('.search-summary');
                    let pagination = $(parent).find('.search-pagination');

                    add_summary(summary, paginationData, value);
                    if (paginationData.total_pages > 1) {
                        pagination.find('ul').html(search_pagination(paginationData.current, paginationData.total_pages)).fadeIn(200);
                    }
                    else {
                        pagination.find('ul').fadeOut(200);
                    }


                    if (paginationData.current > 1) {
                        pagination.find('.search-prev-page').fadeIn(0).attr('data-page', paginationData.current - 1);
                    }
                    else {
                        pagination.find('.search-prev-page').fadeOut(0);
                    }

                    if (paginationData.current < paginationData.total_pages) {
                        pagination.find('.search-next-page').fadeIn(0).attr('data-page', paginationData.current + 1);
                    }
                    else {
                        pagination.find('.search-next-page').fadeOut(0);
                    }
                }

                $(parent).fadeIn(200).removeClass('hidden').find(child).html(links);

            }).catch(error => {
                console.log(`error: ${error}`);
            });
    }

    function add_summary(summary, paginationData, value) {
        summary.find('.current-page').text(paginationData.current);
        summary.find('.total-pages').text(paginationData.total_pages);
        summary.find('.total-results').text(paginationData.total_results);
        summary.find('.search-value').text(value);
    }

    function search_pagination(current, total_pages) {

        let start = current;

        if (start > total_pages - 4) {
            start = total_pages - 4;
        }

        start = (0 === start) ? 1 : start;

        let pages = '';
        let count_pages = 0;
        for (let index = start; index <= total_pages; index++) {
            count_pages++;
            if (count_pages < 6) {
                let active = (current === index) ? 'active' : '';
                pages += `<li class="search-pagination-btn ${active}" data-page="${index}">${index}</li>`;
            }
        }

        return pages;
    }
});